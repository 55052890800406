import React from "react"
import FadeInUp from "../animate/FadeInUp"

const MissionBox = ({ title, description }) => {
  return (
    <FadeInUp>
      <h3 className="mb-3 text-white">{title}</h3>
      <p
        className="text-white"
        style={{ maxWidth: 650, margin: "auto" }}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </FadeInUp>
  )
}

export default MissionBox
