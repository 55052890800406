import React, { useState } from "react"
import FadeInUp from "../animate/FadeInUp"
import quoteImg from "../../images/aAbout page-06.png"
import MissionBox from "./MissionBox"

import "./style.css"
import Icon from "react-icons-kit"
import { lightbulbO } from "react-icons-kit/fa/lightbulbO"
import { flash } from "react-icons-kit/fa/flash"
import { group } from "react-icons-kit/fa/group"

const icons = {
  lightbulbO,
  flash,
  group,
}

const AboutPage = ({
  founderName,
  founderRoles,
  // founderImage,
  founderMessageTitle,
  founderMessageText,
  weThinkAbout,
  missions: { title, list },
}) => {
  const [selected, setSelected] = useState(0)
  return (
    <div className="about-page">
      <FadeInUp>
        <div className="container">
          <div className="b-info-columns-holder b-steps-list col-xs-12 col-sm-12">
            <div className="row equal">
              {weThinkAbout.map(({ title, description, icon }, index) => (
                <div key={title} className="b-info-column col-xs-4 col-sm-4">
                  <div className="info-column-icon">
                    <Icon size={60} icon={icons[icon]} />
                    <span className="step-number">{index + 1}</span>
                  </div>
                  <h6 className="info-column-title">{title}</h6>
                  <div className="text-muted">
                    <p>{description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </FadeInUp>
      <FadeInUp>
        <div style={{ backgroundColor: "#333" }} className="b-history">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="b-upper-title text-center slabtextdone">
                  <span
                    className="slabtext slabtext-linesize-0 slabtext-linelength-7"
                    style={{
                      color: "#222",
                      wordSpacing: 0,
                      letterSpacing: 0,
                    }}
                  >
                    {title}
                  </span>
                </h3>

                <div className="b-pager-slideshow-holder text-center">
                  <MissionBox {...list[selected]} />
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {list.map(({ name }, missionIndex) => (
                    <span
                      key={missionIndex}
                      style={{
                        fontWeight: 700,
                        fontSize: 18,
                        padding: 15,
                        display: "block",
                        textTransform: "uppercase",
                      }}
                      role="button"
                      aria-label={name}
                      aria-hidden={undefined}
                      aria-pressed="mixed"
                      onClick={() => setSelected(missionIndex)}
                      className={`pointer ${selected === missionIndex &&
                        "text-secondary"}`}
                    >
                      <span className="pager-title">{name}</span>
                      <span className="circle">
                        <span className="inner-circle"></span>
                      </span>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeInUp>
      <FadeInUp>
        <div className="b-quote">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6 wow slideInLeft">
                <blockquote
                  className="b-quote-caption clearfix"
                  style={{ marginTop: "-50px" }}
                >
                  <div className="quote-icon">
                    <i className="fa fa-quote-left" aria-hidden="true"></i>
                  </div>
                  <h3 className="quote-title">{founderMessageTitle}</h3>
                  <p className="quote-text text-muted">{founderMessageText}</p>

                  <div className="quote-author">
                    <div
                      className="quote-author-name"
                      style={{ fontSize: "152%" }}
                    >
                      {founderName}
                    </div>
                    <div
                      className="quote-author-position text-muted"
                      style={{ fontSize: "110%" }}
                    >
                      {founderRoles}
                    </div>
                  </div>
                </blockquote>
              </div>
              <div className="col-xs-12 col-sm-6">
                <div className="b-quote-photo">
                  <img
                    src={quoteImg}
                    className="img-responsive"
                    alt="benchmark services"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeInUp>
    </div>
  )
}

export default AboutPage
