import React from "react"

import Layout from "../components/layout"
import AboutMainArea from "../components/AboutMainArea"
import AboutPage from "../components/AboutPage"
import { graphql } from "gatsby"
import Arrow from "../components/Arrow"

export default function AboutUsTemplate({
  data: {
    markdownRemark: {
      frontmatter: { title, description, lang, image, ...restOfFrontmatter },
    },
  },
}) {
  return (
    <Layout description={description} title={title} lang={lang}>
      <AboutMainArea
        image={image?.childImageSharp.fluid.src}
        title={title}
        description={description}
      />
      <div className="b-homepage-content">
        <div className="b-layer-main">
          <Arrow />
          <AboutPage {...restOfFrontmatter} />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        lang
        description
        weThinkAbout {
          title
          icon
          description
        }
        missions {
          title
          list {
            name
            title
            description
          }
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        founderName
        founderRoles
        founderImage
        founderMessageTitle
        founderMessageText
      }
    }
  }
`
