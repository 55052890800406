import React from "react"
import { Link } from "gatsby"
import "./style.css"
import useLang from "../../hooks/useLang"
import MainAreaSection from "../MainAreaSection"

export default function AboutMainArea({ image, title, description }) {
  const isAr = useLang() === "ar"
  return (
    <MainAreaSection
      img={image}
      title={title}
      currentPage={isAr ? "عن بنشمارك" : "About"}
    >
      <div className="heading-text">
        <p>{description}</p>
      </div>
    </MainAreaSection>
  )
}
